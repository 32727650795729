<template>
  <div>
    <div class="BjIo5w">
      <div class="mcsiKT">
        <b-form-checkbox
          v-model="selectAllShops"
          @input="AllProduct('AllShops')"
        />
      </div>
      <div class="yl931K">
        เลือกทั้งหมด ({{ Total.Order }})
      </div>
      <div class="pZMZa7">
        ราคาต่อชิ้น
      </div>
      <div class="lKFOxX">
        จำนวน
      </div>
      <div class="_5f317z">
        ราคารวม
      </div>
      <div class="+4E7yJ">
        แอคชั่น
      </div>
    </div>
    <!-- 5 -->
    <div v-if="items.shops.length > 0">
      <div
        v-for="(shop, inshop) in items.shops"
        :key="inshop"
        class="_48e0yS"
      >
        <div class="SFF7z2">
          <div class="xP1eaK">
            <div class="_5sTIHk">
              <b-form-checkbox
                v-model="selectAllProductArr[inshop]"
                @input="AllProduct('AllProducts',selectAllProductArr[inshop], shop.products)"
              />
            </div>
            <span
              class="wJCpl6"
              style="margin-left: 10px"
            >{{ shop.storename }}
            </span>
            <div class="mx-3 demo-space-x">
              <b-form-checkbox
                v-model="shop.packbox"
                checked="true"
                name="check-button"
                switch
                inline
                @input="ChangeHeadShop(shop)"
              >
                ตีลังไม้
              </b-form-checkbox>
              <b-form-checkbox
                v-model="shop.checkproduct"
                checked="true"
                name="check-button"
                switch
                inline
                @input="ChangeHeadShop(shop)"
              >
                เช็คสินค้า
              </b-form-checkbox>

            </div>
            <div class="O-c69N" />
          </div>
        </div>

        <div
          v-for="(item,index) in shop.products"
          :key="index"
          class="Eb+POp"
        >
          <div class="VPZ9zs">
            <div class="zoXdNN">
              <div class="lgcEHJ">
                <div class="_5sTIHk">
                  <b-form-checkbox
                    v-model="items.shops[inshop].products[index].SelectProduct"
                    @input="AllProduct('Product')"
                  />
                </div>
              </div>
              <div class="eUrDQm">
                <div class="LAQKxn">

                  <a
                    title="text"
                    @click="GoProduct(item,shop.provider)"
                  >
                    <div
                      class="WanNdG"
                      :style="{ backgroundImage: 'url(' + (item.color_img ? item.color_img : item.size_img ? item.size_img : item.picture) + ')' }"
                    />
                  </a>
                  <div class="TyNN8t">
                    <a
                      class="JB57cn"
                      title="text"
                      @click="GoProduct(item,shop.provider)"
                    >{{ item.name }}</a>
                    <div
                      class="eQNnTs"
                      style="
                      background-image: url('https://down-th.img.susercontent.com/file/th-50009109-741ebbf31c114b47d49fc38d39d61d21');
                    "
                    />
                    <div class="QRuJU-">
                      <span />
                    </div>
                  </div>
                </div>
              </div>

              <div class="o7pJBk">
                <div class="MBOFLv">
                  <div
                    class="S-Rdfh"
                    role="button"
                    tabindex="0"
                  >
                    <!-- <div class="rcEQuz">
                      ตัวเลือกสินค้า: <button class="_75YZdf" />
                    </div> -->
                    <div
                      v-if="item.color_title"
                      class="dcPz7Y"
                    >
                      สี: {{ item.color_title }}
                    </div>
                    <div
                      v-if="item.size_title"
                      class="dcPz7Y"
                    >
                      ขนาด: {{ item.size_title }}
                    </div>
                    <div
                      v-if="item.details"
                      class="dcPz7Y"
                    >
                      รายละเอียด: {{ item.details }}
                    </div>
                  </div>
                  <div />
                </div>
              </div>
              <div class="G7E4B7">
                <div>
                  <span class="M-AAFK">¥ {{ Commas(item.price) }}</span>
                  <span class="M-AAFK">฿ {{ Commas(item.price * Rate) }}</span>
                </div>
              </div>
              <div class="MRh9G6">
                <div class="mXmGu+ anan-input-quantity">
                  <b-form-spinbutton
                    id="sb-inline"
                    v-model="item.quantity"
                    inline
                    max="999999"
                    min="1"
                    @change="(ChangeVal(item,shop))"
                  />
                </div>
              </div>
              <div class="ofQLuG">
                <span>¥ {{ Commas(item.price * item.quantity) }}</span>
                <span>฿ {{ Commas((item.price * Rate) * item.quantity) }}</span>
              </div>
              <div class="mhcjog _0p-F-m">
                <button
                  class="fX1Y2g"
                  @click="DeleteProduct(item,shop)"
                >
                  ลบ
                </button>
              </div>
            </div>
          </div>
          <div class="ur8hET" />
        </div>
      </div>
    </div>

    <div class="rnocow uEhFYV">
      <div class="exGqmz WjgNv5">
        <div class="C9vN6U">
          โค้ดส่วนลดของคุณ
        </div>
        <div class="GdUwdD" />
        <span class="ORcGEd">เลือกโค้ดส่วนลด</span>
      </div>
      <div class="h-Ivjs _1Xi-wS" />
      <div class="nPjHJw ceZa-G">
        <label class="stardust-checkbox stardust-checkbox--disabled">
          <input
            class="stardust-checkbox__input"
            type="checkbox"
          >
          <div class="stardust-checkbox__box" />
        </label>
      </div>
      <div class="nPjHJw K98a1N _1dLWkp r71pqT">
        <div class="korYGr">
          Coins
        </div>
        <div class="WpXi0X">
          คุณยังไม่ได้เลือกสินค้า
        </div>
      </div>
      <div class="nPjHJw _4kUN8t _9d15-k">
        -฿0
      </div>
      <div class="h-Ivjs ivEpTY" />
      <div class="s1Gxkq c2pfrq">
        <div class="wqjloc">
          <label class="stardust-checkbox">
            <b-form-checkbox
              v-model="selectAllShops"
              @input="AllProduct('AllShops')"
            />
            <div class="stardust-checkbox__box" />
          </label>
        </div>
        <button class="iGlIrs clear-btn-style">
          เลือกทั้งหมด ({{ Total.Order }})
        </button><button class="clear-btn-style ukPYq9">
          ลบ
        </button>
        <div class="" />
        <button class="clear-btn-style KbDVuv">
          ย้ายไปยังสินค้าที่ถูกใจ
        </button>
        <div class="UlxAss" />
        <div class="UQv8V6">
          <div class="fyYBP1">
            <div class="aiyQAr">
              <div class="A-CcKC">
                รวม ({{ Total.Order }} สินค้า):
              </div>
              <div class="WC0us+">
                ¥ {{ Commas(Total.Price) }}
              </div>
            </div>
          </div>
          <div class="onR5FG" />
        </div>
        <button
          class="anan-button-solid anan-button-solid--primary"
          @click="Submit"
        >
          <span class="TTXpRG">สั่งสินค้า</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormSpinbutton } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BFormSpinbutton,
  },
  data() {
    return {
      selectRow: [],
      selectAll: false,
      selectAllShops: false,
      selectAllProductArr: [],
      // selectAllProduct: false,
      packBox: false,
      checkProduct: false,
      value: 20,
      items: [],
      sum: [],
      Rate: 0,
      product: [
        {
          id: 1, name: '[2ชิ้น2บาท]MW007กระดาษทิชชู่หนา 4 ชั้น 300 แผ่น ทิชชู่แบบดึงเหนียว แข็งแรง แบบพกพา กระดาษเช็ดมือ(แพ็ค1ชิ้น)', price: 20, qty: 1,
        },
        {
          id: 2, name: 'test2', price: 20, qty: 1,
        },
        {
          id: 3, name: 'test3', price: 20, qty: 1,
        },
        {
          id: 4, name: 'test4', price: 20, qty: 1,
        },
        {
          id: 5, name: 'test5', price: 20, qty: 1,
        },
        {
          id: 6, name: 'test6', price: 20, qty: 1,
        },
      ],
      Total: {
        Order: 0,
        Price: 0,
      },
    }
  },
  computed: {

  },
  created() {

  },
  mounted() {
    this.GetMycart()
    this.GetRate()
  },
  methods: {
    GetMycart() {
      this.$http
        .get('/cart/mycart')
        .then(response => {
          this.items = response.data
          if (this.items.shops.length > 0) {
            // eslint-disable-next-line
            this.items.shops.forEach(element => {
              // eslint-disable-next-line no-param-reassign
              element.SelectShop = false
              this.selectAllProductArr.push(false)
              element.products.forEach(product => {
                // eslint-disable-next-line no-param-reassign
                product.SelectProduct = false
              })
            })
          }
        })
        .catch(error => console.log(error))
    },
    AllProduct(SelectType, SelectShop, Products) {
      if (SelectType) {
        if (SelectType === 'AllShops') {
          if (this.selectAllShops) {
            this.items.shops.forEach((element, index) => {
            // eslint-disable-next-line no-param-reassign
              element.SelectShop = true
              this.selectAllProductArr[index] = true
              element.products.forEach(product => {
              // eslint-disable-next-line no-param-reassign
                product.SelectProduct = true
              })
            })
          } else {
            this.items.shops.forEach((element, index) => {
            // eslint-disable-next-line no-param-reassign
              element.SelectShop = false
              this.selectAllProductArr[index] = false
              element.products.forEach(product => {
              // eslint-disable-next-line no-param-reassign
                product.SelectProduct = false
              })
            })
          }
        } else if (SelectType === 'AllProducts') {
          if (SelectShop) {
            Products.forEach(product => {
              // eslint-disable-next-line no-param-reassign
              product.SelectProduct = true
            })
          } else {
            Products.forEach(product => {
              // eslint-disable-next-line no-param-reassign
              product.SelectProduct = false
            })
          }
        }
        this.CalTotal()
      }
    },
    CalTotal() {
      this.Total.Order = 0
      this.Total.Price = 0
      if (this.items && this.items.shops.length > 0) {
        this.items.shops.forEach(Items => {
          Items.products.forEach(product => {
            if (product.SelectProduct) {
              this.Total.Order += 1
              this.Total.Price += (Number(product.price) * Number(product.quantity))
            }
          })
        })
      }
    },
    async ChangeHeadShop(Shop) {
      try {
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          shops_id: Shop._id,
          packbox: Shop.packbox,
          checkproduct: Shop.checkproduct,
        }
        await this.$http.post('/cart/CheckAndPack', obj)
      } catch (error) {
        console.log(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async ChangeVal(Product, Shop) {
      try {
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          shops_id: Shop._id,
          // eslint-disable-next-line no-underscore-dangle
          products_id: Product._id,
          quantity: Product.quantity,
        }
        const { data: res } = await this.$http.post('/cart/ChangeQuantity', obj)
        if (res.status) {
          // eslint-disable-next-line no-param-reassign
          Product.price = res.price
        }
      } catch (error) {
        console.log(error)
      }
    },
    Submit() {
      const obj = []
      const Order = []
      // eslint-disable-next-line no-restricted-syntax
      for (const Items of this.items.shops) {
        const FilterProduct = Items.products.filter(product => product.SelectProduct)
        if (FilterProduct && FilterProduct.length > 0) {
          const OrderObj = {
          // eslint-disable-next-line no-underscore-dangle
            shops_id: Items._id,
            storename: Items.storename,
            checkproduct: Items.checkproduct,
            packbox: Items.packbox,
            provider: Items.provider,
            products: FilterProduct,
          }
          Order.push(OrderObj)
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const product of Items.products) {
          if (product.SelectProduct) {
            obj.push({
              // eslint-disable-next-line no-underscore-dangle
              shops_id: Items._id,
              // eslint-disable-next-line no-underscore-dangle
              products_id: product._id,
            })
          }
        }
      }
      if (obj.length === 0) {
        this.SwalError('กรุณาเลือกสินค้า !!')
      } else {
        const OrderStr = JSON.stringify(Order)
        const OrderIdStr = JSON.stringify(obj)
        localStorage.setItem('Order', OrderStr)
        localStorage.setItem('OrderId', OrderIdStr)
        this.$router.push({ name: 'checkout' })
      }
    },
    DeleteProduct(data, shop) {
      this.$swal({
        title: 'ยืนยันที่จะลบสินค้า ?',
        text: `ชื่อ ${data.name ? data.name : '-'} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            shops_id: shop._id,
            // eslint-disable-next-line no-underscore-dangle
            products_id: data._id,
          }
          this.$http
            .post('/cart/deleteIncart', obj)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.GetMycart()
              this.Success('ลบสินค้าสำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    GoProduct(item, provider) {
      if (provider === 'anancargo') {
        this.$router.push({ name: 'product', params: { id: item.id } })
      } else {
        this.GetProductByUrl(item.url)
      }
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get('/product/rate')
        if (res.status) {
          this.Rate = res.data.cSelling
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetProductByUrl(URLData) {
      const obj = {
        provider: '',
        id: '',
      }
      const url = new URL(URLData)
      const { host, pathname, search } = url
      if (host && host.length > 0) {
        const index1688 = host.indexOf('1688')
        const indextaobao = host.indexOf('taobao')
        const indextmall = host.indexOf('tmall')
        if (index1688 > -1) {
          obj.provider = '1688'
          if (host === 'mindx.1688.com') {
            obj.id = url.searchParams.get('offerId')
          } else {
            const IndexCheck = pathname.indexOf('/offer/')
            if (IndexCheck > -1) {
              const of = new RegExp('/offer/', 'g')
              const ht = new RegExp('.html', 'g')
              obj.id = pathname.replace(of, '')
              obj.id = obj.id.replace(ht, '')
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = 'taobao'
          const IndexCheck = search.indexOf('itemIds')
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemIds')
          } else {
            obj.id = url.searchParams.get('id')
          }
        } else if (indextmall > -1) {
          obj.provider = 'tmall'
          obj.id = url.searchParams.get('id')
        }
        if (obj.provider && obj.id) {
          this.$router.push({ name: `product-${obj.provider}`, params: { id: obj.id } })
        } else {
          this.Alert(false, 'ไม่พบข้อมูลสินค้า', 'กรุณากรอก URL ใหม่ให้ถูกต้อง')
        }
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
